import { routeType, buttonType, methodProps, values } from "./types";

// partners logo
import partner1 from "./assets/partner1.png";
import partner2 from "./assets/partner2.png";
import partner3 from "./assets/partner3.png";
import partner4 from "./assets/partner4.png";
import partner5 from "./assets/partner5.png";

// waste types images
import waste1 from "./assets/waste1.png";
import waste2 from "./assets/waste2.png";
import waste3 from "./assets/waste3.png";
import waste4 from "./assets/waste4.png";
import waste5 from "./assets/waste5.png";
import waste6 from "./assets/waste6.png";
import waste7 from "./assets/waste7.png";
import waste8 from "./assets/waste8.png";
import waste9 from "./assets/waste9.png";
import waste10 from "./assets/waste10.png";

// collection methods images
import collection1 from "./assets/collection1.png";
import collection2 from "./assets/collection2.png";
import collection3 from "./assets/collection3.png";

import testimonial from "./assets/small_img.png";

import envelope from "./assets/envelope.png";
import phone from "./assets/phone.png";

// social media icons
import twitter from "./assets/twitter.png";
import facebook from "./assets/facebook.png";
import instagram from "./assets/instagram.png";
import linkedin from "./assets/linkedin.png";

import dispose from "./assets/dispose.png";

import team from "./assets/team.png";

import envelope2 from "./assets/envelope2.png";
import phone2 from "./assets/phone2.png";

// social media icons
import twitter2 from "./assets/twitter2.png";
import facebook2 from "./assets/facebook2.png";
import instagram2 from "./assets/instagram2.png";
import linkedin2 from "./assets/linkedin2.png";

import SwiperOptions from "swiper";

export const LinkRoutes: routeType = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About Us",
    path: "/about",
  },
  {
    name: "Blog",
    path: "/blog",
  },
  {
    name: "Contact Us",
    path: "/contact",
  },
];

export const ButtonType: buttonType = {
  light: "bg-white text-primary",
  dark: "bg-primary text-white",
};

export const PartnerLogos = [partner1, partner2, partner3, partner4, partner5];

export const WastesData = [
  {
    name: "Pet containers",
    img: waste2,
  },

  {
    name: "Plastic buckets",
    img: waste7,
  },
  {
    name: "glass containers",
    img: waste9,
  },
  {
    name: "used papers",
    img: waste10,
  },
  {
    name: "Aluminium can containers",
    img: waste1,
  },
  {
    name: "Pure water sachets",
    img: waste6,
  },
];

export const MethodTypes: methodProps = [
  {
    title: "recyclables pick up",
    content:
      "Lorem ipsum dolor sit amet consectetur. Tincidunt ipsum enim tellus rhoncus mattis. Id maecenas tincidunt dapibus auctor lectus tortor ligula sed. Aliquam tortor viverra phasellus at euismod id faucibus sem. ",
    img: collection1,
  },
  {
    title: "recyclables drop off",
    content:
      "Lorem ipsum dolor sit amet consectetur. Tincidunt ipsum enim tellus rhoncus mattis. Id maecenas tincidunt dapibus auctor lectus tortor ligula sed. Aliquam tortor viverra phasellus at euismod id faucibus sem. ",
    img: collection2,
  },
  {
    title: "corporate services",
    content:
      "Lorem ipsum dolor sit amet consectetur. Tincidunt ipsum enim tellus rhoncus mattis. Id maecenas tincidunt dapibus auctor lectus tortor ligula sed. Aliquam tortor viverra phasellus at euismod id faucibus sem. ",
    img: collection3,
  },
];

export const FaqContent = [
  {
    question: "What is GJC Recyling",
    answer:
      "Lorem ipsum dolor sit amet consectetur. Tincidunt ipsum enim tellus rhoncus mattis. Id maecenas tincidunt dapibus auctor lectus tortor ligula sed. Aliquam tortor viverra phasellus at euismod id faucibus sem. ",
  },
  {
    question: "What is GJC Recyling",
    answer:
      "Lorem ipsum dolor sit amet consectetur. Tincidunt ipsum enim tellus rhoncus mattis. Id maecenas tincidunt dapibus auctor lectus tortor ligula sed. Aliquam tortor viverra phasellus at euismod id faucibus sem. ",
  },
  {
    question: "What is GJC Recyling",
    answer:
      "Lorem ipsum dolor sit amet consectetur. Tincidunt ipsum enim tellus rhoncus mattis. Id maecenas tincidunt dapibus auctor lectus tortor ligula sed. Aliquam tortor viverra phasellus at euismod id faucibus sem. ",
  },
];

export const testimonialDatas = [
  {
    name: "Ajani Ben Dara",
    country: "Nigeria",
    content:
      "My experience so far with tier Money is excellent! No issue with this money wallet! I recommend tier Money to anyone in seek of financial wallet! The app is  more superb when it comes international service like sending, receiving money and exchanging of like Europe, American, etc ",
    img: testimonial,
  },
  {
    name: "Ajani Ben Dara",
    country: "Nigeria",
    content:
      "My experience so far with tier Money is excellent! No issue with this money wallet! I recommend tier Money to anyone in seek of financial wallet! The app is  more superb when it comes international service like sending, receiving money and exchanging of like Europe, American, etc ",
    img: testimonial,
  },
  {
    name: "Ajani Ben Dara",
    country: "Nigeria",
    content:
      "My experience so far with tier Money is excellent! No issue with this money wallet! I recommend tier Money to anyone in seek of financial wallet! The app is  more superb when it comes international service like sending, receiving money and exchanging of like Europe, American, etc ",
    img: testimonial,
  },
  {
    name: "Ajani Ben Dara",
    country: "Nigeria",
    content:
      "My experience so far with tier Money is excellent! No issue with this money wallet! I recommend tier Money to anyone in seek of financial wallet! The app is  more superb when it comes international service like sending, receiving money and exchanging of like Europe, American, etc ",
    img: testimonial,
  },
  {
    name: "Ajani Ben Dara",
    country: "Nigeria",
    content:
      "My experience so far with tier Money is excellent! No issue with this money wallet! I recommend tier Money to anyone in seek of financial wallet! The app is  more superb when it comes international service like sending, receiving money and exchanging of like Europe, American, etc ",
    img: testimonial,
  },
  {
    name: "Ajani Ben Dara",
    country: "Nigeria",
    content:
      "My experience so far with tier Money is excellent! No issue with this money wallet! I recommend tier Money to anyone in seek of financial wallet! The app is  more superb when it comes international service like sending, receiving money and exchanging of like Europe, American, etc ",
    img: testimonial,
  },
  {
    name: "Ajani Ben Dara",
    country: "Nigeria",
    content:
      "My experience so far with tier Money is excellent! No issue with this money wallet! I recommend tier Money to anyone in seek of financial wallet! The app is  more superb when it comes international service like sending, receiving money and exchanging of like Europe, American, etc ",
    img: testimonial,
  },
  {
    name: "Ajani Ben Dara",
    country: "Nigeria",
    content:
      "My experience so far with tier Money is excellent! No issue with this money wallet! I recommend tier Money to anyone in seek of financial wallet! The app is  more superb when it comes international service like sending, receiving money and exchanging of like Europe, American, etc ",
    img: testimonial,
  },

  {
    name: "Ajani Ben Dara",
    country: "Nigeria",
    content:
      "My experience so far with tier Money is excellent! No issue with this money wallet! I recommend tier Money to anyone in seek of financial wallet! The app is  more superb when it comes international service like sending, receiving money and exchanging of like Europe, American, etc ",
    img: testimonial,
  },
];

export const BenefitData = [
  {
    title: "Best-in-class performance tracking",
    content:
      "Lorem ipsum dolor sit amet consectetur. Tincidunt ipsum enim tellus rhoncus mattis. Id maecenas tincidunt dapibus auctor lectus tortor ligula sed. Aliquam tortor viverra phasellus at euismod id faucibus sem. ",
    img: collection1,
  },
  {
    title: "Customer-centric model of operations",
    content:
      "Lorem ipsum dolor sit amet consectetur. Tincidunt ipsum enim tellus rhoncus mattis. Id maecenas tincidunt dapibus auctor lectus tortor ligula sed. Aliquam tortor viverra phasellus at euismod id faucibus sem. ",
    img: collection2,
  },
];

export const FooterRoutes = {
  qlinks: {
    title: "Quick Links",
    routes: [
      {
        path: "/",
        name: "Home",
      },
      {
        path: "/about",
        name: "About Us",
      },
      {
        path: "/contact",
        name: "Contact Us",
      },
    ],
  },
  services: {
    title: "Serices",
    routes: [
      {
        path: "/",
        name: "Support",
      },
      {
        path: "/",
        name: "Cookie Policy",
      },
      {
        path: "/",
        name: "Privacy Policy",
      },
    ],
  },
  support: {
    title: "Support & Legal",
    routes: [
      {
        path: "/",
        name: "Recyclables PickUp",
      },
      {
        path: "/",
        name: "Recyclables Drop-off",
      },
      {
        path: "/",
        name: "Corporate Services",
      },
    ],
  },
  contact: {
    title: "Get in Touch",
    routes: [
      { icon: phone, name: "+2347065911838" },
      { icon: phone, name: "+2349126390288" },
      { icon: envelope, name: "gjcrecycling@gmail.com" },
    ],
    title2: "Social Media",
    routes2: [
      {
        icon: linkedin,
        name: "Linkedin",
      },
      {
        icon: instagram,
        name: "instagram",
      },
      {
        icon: facebook,
        name: "facebook",
      },
      {
        icon: twitter,
        name: "twitter",
      },
    ],
  },
};

export const PorfolioData = [
  {
    title: "King Baudouin African Development Prize 2023 -2024",
    content:
      "Lorem ipsum dolor sit amet consectetur. Sit amet tortor mauris nisl viverra semper mauris augue venenatis. Id aliquam ornare cursus sem amet nibh. Nec vestibulum diam semper facilisis platea ridiculus. Gravida scelerisque pellentesque egestas nisl nunc volutpat mi viverra. Gravida eget id id nunc sem hendrerit. Et sagittis maecenas non scelerisque lacus sed sit. Phasellus nisl eget senectus ipsum donec sed molestie facilisis.Tincidunt purus nibh quam risus blandit auctor. Turpis imperdiet lectus sit commodo diam non nam scelerisque faucibus. Tellus id odio integer nulla semper egestas eu eget. Risus aliquam dictum a integer vel at. Est neque scelerisque feugiat faucibus nulla. Mi pretium sed nulla cras arcu praesent. Velit pulvinar eget ullamcorper ac netus. Eget felis gravida id non pellentesque diam. Sit et ultricies at tristique neque tempor imperdiet arcu commodo. Cras amet iaculis ut nunc ipsum.Ullamcorper ullamcorper id eu consequat massa quisque amet velit amet. Pulvinar feugiat orci tortor magnis adipiscing scelerisque tempor. Hendrerit lectus lacinia massa dui. Lacinia tristique nunc massa diam nisl vestibulum elit. Dolor vulputate in ultricies orci nulla. At tincidunt in in nulla accumsan placerat cursus. At dui in facilisis ac porta est ullamcorper. Pharetra ipsum posuere aliquam ac sapien donec sagittis auctor nec. Sed senectus viverra  ",
    to: "/portfolio",
    img: dispose,
    date: "22-02-2020",
  },
  {
    title: "King Baudouin African Development Prize 2023 -2024",
    content:
      "Lorem ipsum dolor sit amet consectetur. Sit amet tortor mauris nisl viverra semper mauris augue venenatis. Id aliquam ornare cursus sem amet nibh. Nec vestibulum diam semper facilisis platea ridiculus. Gravida scelerisque pellentesque egestas nisl nunc volutpat mi viverra. Gravida eget id id nunc sem hendrerit. Et sagittis maecenas non scelerisque lacus sed sit. Phasellus nisl eget senectus ipsum donec sed molestie facilisis.Tincidunt purus nibh quam risus blandit auctor. Turpis imperdiet lectus sit commodo diam non nam scelerisque faucibus. Tellus id odio integer nulla semper egestas eu eget. Risus aliquam dictum a integer vel at. Est neque scelerisque feugiat faucibus nulla. Mi pretium sed nulla cras arcu praesent. Velit pulvinar eget ullamcorper ac netus. Eget felis gravida id non pellentesque diam. Sit et ultricies at tristique neque tempor imperdiet arcu commodo. Cras amet iaculis ut nunc ipsum.Ullamcorper ullamcorper id eu consequat massa quisque amet velit amet. Pulvinar feugiat orci tortor magnis adipiscing scelerisque tempor. Hendrerit lectus lacinia massa dui. Lacinia tristique nunc massa diam nisl vestibulum elit. Dolor vulputate in ultricies orci nulla. At tincidunt in in nulla accumsan placerat cursus. At dui in facilisis ac porta est ullamcorper. Pharetra ipsum posuere aliquam ac sapien donec sagittis auctor nec. Sed senectus viverra  ",
    to: "/portfolio",
    img: dispose,
    date: "22-02-2020",
  },
  {
    title: "King Baudouin African Development Prize 2023 -2024",
    content:
      "Lorem ipsum dolor sit amet consectetur. Sit amet tortor mauris nisl viverra semper mauris augue venenatis. Id aliquam ornare cursus sem amet nibh. Nec vestibulum diam semper facilisis platea ridiculus. Gravida scelerisque pellentesque egestas nisl nunc volutpat mi viverra. Gravida eget id id nunc sem hendrerit. Et sagittis maecenas non scelerisque lacus sed sit. Phasellus nisl eget senectus ipsum donec sed molestie facilisis.Tincidunt purus nibh quam risus blandit auctor. Turpis imperdiet lectus sit commodo diam non nam scelerisque faucibus. Tellus id odio integer nulla semper egestas eu eget. Risus aliquam dictum a integer vel at. Est neque scelerisque feugiat faucibus nulla. Mi pretium sed nulla cras arcu praesent. Velit pulvinar eget ullamcorper ac netus. Eget felis gravida id non pellentesque diam. Sit et ultricies at tristique neque tempor imperdiet arcu commodo. Cras amet iaculis ut nunc ipsum.Ullamcorper ullamcorper id eu consequat massa quisque amet velit amet. Pulvinar feugiat orci tortor magnis adipiscing scelerisque tempor. Hendrerit lectus lacinia massa dui. Lacinia tristique nunc massa diam nisl vestibulum elit. Dolor vulputate in ultricies orci nulla. At tincidunt in in nulla accumsan placerat cursus. At dui in facilisis ac porta est ullamcorper. Pharetra ipsum posuere aliquam ac sapien donec sagittis auctor nec. Sed senectus viverra  ",
    to: "/portfolio",
    img: dispose,
    date: "22-02-2020",
  },
];

export const TeamData = [
  {
    name: "Ajane Ben Dara",
    role: "C.E.O",
    img: team,
  },
  {
    name: "Ajane Ben Dara",
    role: "C.E.O",
    img: team,
  },
  {
    name: "Ajane Ben Dara",
    role: "C.E.O",
    img: team,
  },
];

export const pickUpData = [
  {
    place: "Ebute Meta Hub",
    areas: [
      "Ebute Meta",
      "Oyinbo",
      "Ajegunle",
      "Yaba",
      "Orile Alagomeji",
      "Costain",
    ],
    phone: "08107492331",
  },
  {
    place: "Ebute Meta Hub",
    areas: [
      "Ebute Meta",
      "Oyinbo",
      "Ajegunle",
      "Yaba",
      "Orile Alagomeji",
      "Costain",
    ],
    phone: "08107492331",
  },
  {
    place: "Ebute Meta Hub",
    areas: [
      "Ebute Meta",
      "Oyinbo",
      "Ajegunle",
      "Yaba",
      "Orile Alagomeji",
      "Costain",
    ],
    phone: "08107492331",
  },
  {
    place: "Ebute Meta Hub",
    areas: [
      "Ebute Meta",
      "Oyinbo",
      "Ajegunle",
      "Yaba",
      "Orile Alagomeji",
      "Costain",
    ],
    phone: "08107492331",
  },
  {
    place: "Ebute Meta Hub",
    areas: [
      "Ebute Meta",
      "Oyinbo",
      "Ajegunle",
      "Yaba",
      "Orile Alagomeji",
      "Costain",
    ],
    phone: "08107492331",
  },
  {
    place: "Ebute Meta Hub",
    areas: [
      "Ebute Meta",
      "Oyinbo",
      "Ajegunle",
      "Yaba",
      "Orile Alagomeji",
      "Costain",
    ],
    phone: "08107492331",
  },
  {
    place: "Ebute Meta Hub",
    areas: [
      "Ebute Meta",
      "Oyinbo",
      "Ajegunle",
      "Yaba",
      "Orile Alagomeji",
      "Costain",
    ],
    phone: "08107492331",
  },
];

export const dropData = [
  {
    place: "Gbagada Phase 2",
    address:
      "Canterbury Health Consult, 20B Lanre Awolokun St, Gbagada, Lagos.",
    number: "08107492331",
    link: "/",
  },
  {
    place: "Gbagada Phase 2",
    address:
      "Canterbury Health Consult, 20B Lanre Awolokun St, Gbagada, Lagos.",
    number: "08107492331",
    link: "/",
  },

  {
    place: "Gbagada Phase 2",
    address:
      "Canterbury Health Consult, 20B Lanre Awolokun St, Gbagada, Lagos.",
    number: "08107492331",
    link: "/",
  },
  {
    place: "Gbagada Phase 2",
    address:
      "Canterbury Health Consult, 20B Lanre Awolokun St, Gbagada, Lagos.",
    number: "08107492331",
    link: "/",
  },
];

export const contactData = {
  contact: {
    title: "Get in Touch",
    routes: [
      { icon: phone2, name: "+23465911838" },
      { icon: envelope2, name: "gjcrecycling@gmail.com" },
    ],
    title2: "Social Media",
    routes2: [
      {
        icon: linkedin2,
        name: "Linkedin",
      },
      {
        icon: instagram2,
        name: "instagram",
      },
      {
        icon: facebook2,
        name: "facebook",
      },
      {
        icon: twitter2,
        name: "twitter",
      },
    ],
  },
};

export const RegisterValues: values = {
  fullName: "",
  phone: "",
  email: "",
  password: "",
  confirmPassword: "",
};

export const SidebarLinks = [
  {
    name: "Dashboard",
    path: "/dashboard",
  },
  {
    name: "Bookings",
    path: "/dashboard/bookings",
  },
  {
    name: "Profile",
    path: "/dashboard/profile",
  },
  {
    name: "Logout",
    path: "/login",
  },
];

export const OverviewData = [
  {
    name: "Total bookings",
    amount: 400,
    percent: "+11.01%",
    type: "credit",
  },
  {
    name: "Successful Pickup",
    amount: 300,
    percent: "+9.15%",
    type: "credit",
  },
  {
    name: "Pending Pickup ",
    amount: 2,
    percent: "-0.56%",
    type: "debit",
  },
  {
    name: "Cancelled Pickup",
    amount: 98,
    percent: "-1.48%",
    type: "debit",
  },
];

export const LanguageData = [
  {
    name: "English",
    value: "english",
  },
  {
    name: "French",
    value: "french",
  },
];
type pickUpType = Array<{
  bookingId: number;
  bookingDate: string;
  location: string;
  phone: string;
  pickupDate: string;
  status: string;
}>;
export const AllPickups: pickUpType = [
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "pending",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "pending",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "pending",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "pending",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "successful ",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "successful ",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "successful ",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "successful ",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "successful ",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "cancelled",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "cancelled",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "cancelled",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "cancelled",
  },
];

export const headerData = [
  "Booking ID",
  "Booking Date",
  "Location",
  "Phone Number",
  "Pickup date",
  "Booking Status",
];

export const buttonStates = [
  {
    name: "All Bookings",
    status: "all",
  },
  {
    name: "New Booking",
    status: "all",
  },
  {
    name: "Pending PickUp",
    status: "pending",
  },
  {
    name: "Successful Pickup",
    status: "successful",
  },
  {
    name: "Cancelled Pickup",
    status: "cancelled",
  },
];

export const swiperOptions: SwiperOptions = {
  centerInsufficientSlides: true,
  slidesPerView: 2,
  spaceBetween: 10,
  breakpoints: {
    500: {
      slidesPerView: 2,
    },
    840: {
      slidesPerView: 3,
    },
  },
  autoplay: {
    delay: 4000, // Set the delay between slide transitions in milliseconds
    // Allow autoplay to continue even when the user interacts with the swiper
  },

  pagination: {
    clickable: true,
  },
};

export const swiperOptions2: SwiperOptions = {
  centerInsufficientSlides: true,
  slidesPerView: 1,
  spaceBetween: 10,
  breakpoints: {
    500: {
      slidesPerView: 1,
    },
    840: {
      slidesPerView: 3,
    },
  },
  autoplay: {
    delay: 4000, // Set the delay between slide transitions in milliseconds
    // Allow autoplay to continue even when the user interacts with the swiper
  },

  pagination: {
    clickable: true,
  },
};

export const aboutData = [
  {
    title: "Converting Waste to Cash",
    text: " We believe that waste should not be seen as a burden but as a valuable resource. By partnering with us, you have the opportunity to turn your waste into cash. We buy various recyclable materials, including plastics, from households, estates, and communities in Ibadan. So, instead of throwing your waste away, you can now make a profit from it.",
  },
  {
    title: "Eco-Friendly Practices  ",
    text: " We are committed to sustainability and minimizing our ecological footprint. Our waste management processes prioritize recycling and proper disposal, ensuring that materials are given a new life or disposed of responsibly. By working with us, you can contribute to a cleaner and healthier environment for future generations.",
  },
  {
    title: "Convenience and Efficiency",
    text: "We understand that waste management should be hassle-free and convenient. That's why we have designed a seamless process that makes it easy for you to sell your recyclable waste to us. Whether you're an individual, a household, or a community, we provide efficient collection and payment systems, ensuring a smooth experience for everyone involved.",
  },
  {
    title: "Community Engagement",
    text: " We believe that waste management is a collective effort. We actively engage with the community, raising awareness about the importance of waste reduction and recycling. Through educational initiatives and partnerships, we empower individuals and communities to take an active role in preserving the environment and building a sustainable future together.",
  },
];
